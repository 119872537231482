import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../../components/Typography/Typography";
import { useStaticQuery, graphql } from "gatsby";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import { StaticImage } from "gatsby-plugin-image";
import { mobileViewBreakpoint } from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },

  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));

const Article = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "casestudy/Transport_Consulting_Slider.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Transportation Solutions – Rethought"}
      h1
      banner={
        <StaticImage
          src="../../assets/images/casestudy/Transport_Consulting_Slider.jpg"
          alt="transport consulting"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Transportation Solutions – Rethought" }]}
      metaTitle="Cost Effective Transport & Solutions"
      metaDescription="Motus One has a collection of successful & cost-effective transport solutions for long-term growth & profitability. Check out our solutions!"
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography component="h2" customVariant={"h2Bold"}>
            Cost effective consulting transportation solutions
          </Typography>

          <Typography customVariant={"h5Regular"}>
            MOTUS | ONE will change the way you approach your projects concept,
            design, build, delivery, execution, andoperational phases to ensure
            every aspect of your transportation requirements are captured
          </Typography>
        </div>

        <Summary
          title={"Executive summary"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/transport_consulting_execsummary.jpeg"
              draggable="false"
              alt="laptop with coffee on table"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                We have provided specialist consulting services to cities,
                regional authorities, bidding and organizing committees, and
                venues that have enabled us to achieve success at critical
                milestones within project timelines. Prior technical and
                commissioning experience can be invaluable to those responsible
                for delivering the world’s highest profile venues, facilities,
                and events. Our expertise enables agencies to receive
                concentrated support from an operational standpoint and backed
                by engineering, to ensure functionality and efficiency exists at
                the earliest stages
              </p>
            </>
          }
        />

        <Summary
          title={"Categories"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/transport_consulting_categories.jpeg"
              draggable="false"
              alt="pedestrian traffic signal showing do not walk symbols"
            />
          }
          link={undefined}
          description={
            <>
              <ul>
                <li>Traffic Impact Study </li>

                <li>Venue Ingress & Venue Egress </li>

                <li>Vehicle Routing</li>
                <li>Traffic Signal Sequencing</li>

                <li>Way-Finding and Signage Layout and Configuration</li>
                <li>Car Park Management; Car Park Monetization</li>
                <li>Parking Sequencing; Parking Models; Parking Allocation</li>
                <li>CONOP; OPLAN; Policy and Procedure; SOP development</li>
                <li>Expert staff from Olympic / Paralympic Games </li>

                <li>Regional Venues; Municipality Agencies</li>
                <li>Fleet Planning and Delivery</li>

                <li>Alternative Fuel Vehicles</li>
                <li>Autonomous Driving Solutions</li>
              </ul>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
